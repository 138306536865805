import * as defaults from './defaults';
export { defaults as defaultPermissions };
import { allPermissions } from './permissions';
export * from './permissions';

function hasPermission(options) {
  var _a;
  let userPermissions = ((_a = options.userToCheck) == null ? void 0 : _a.permissions) ?? [];
  if (!options.userToCheck)
    return false;
  if (options.userToCheck.roles && options.userToCheck.roles.length >= 1) {
    userPermissions = [
      ...userPermissions,
      ...options.userToCheck.roles.flatMap((r) => r.permissions)
    ];
  }
  if (options.userToCheck.rank === "OWNER") {
    return true;
  }
  if (userPermissions.length <= 0 || !Array.isArray(options.permissionsToCheck)) {
    return false;
  }
  for (const perm of options.permissionsToCheck) {
    if (userPermissions.includes(perm))
      return true;
  }
  if (userPermissions.length <= 0 && typeof options.fallback !== "undefined") {
    return typeof options.fallback === "boolean" ? options.fallback : options.fallback(options.userToCheck) ?? false;
  }
  return false;
}
function getPermissions(user) {
  const permissions = {};
  allPermissions.forEach((name) => {
    permissions[name] = hasPermission({
      userToCheck: user,
      permissionsToCheck: [name]
    });
  });
  return permissions;
}

export { getPermissions, hasPermission };
